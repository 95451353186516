import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { RealEstateDataFooterData, RealEstateDataPage, StatePropertiesSlice } from 'types'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { Link } from 'gatsby'
import {
  ElementHTML,
  getMessageWhatsapp,
  makeItemsNav,
  scrollToElement,
} from '@helpers/helper.rendering'
import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { useGetJobsQuery } from '@apis/mediacore/jobs'
import useQuerySummary from '@hooks/useQuerySummary'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'
import useQueryDevelopment from '@hooks/useQueryDevelopment'
import useQueryProperty from '@hooks/useQueryProperty'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSendContactFormMutation } from '@apis/originalTokkoApi/contact'
import toast from 'react-hot-toast'
import $, { data } from 'jquery'
import { useSelector } from 'react-redux'
import { getfavorites } from '@helpers/helper.favorites'
import { useCreateFormMutation as useSendContactFormMutationMediacore } from '@apis/mediacore/contact'
import icon1 from './icon2.png'
import icon2 from './icon1.png'

interface ExternalProps {
  pages: RealEstateDataPage[]
  currentPage?: RealEstateDataPage[]
  development_id?: string
  property_id?: string
}

const isBrowser = typeof window !== 'undefined'

type CombinedProps = RealEstateDataFooterData & ExternalProps
const Footer7 = ({
  titles,
  modern_version,
  global_data,
  development_id,
  property_id,
  backgroundHexa,
  paragraphHexa,
  titleHexa,
  titleElement,
  advanced_whatsapp,
  hidden,
  button,
  social_links,
  topLogo,
  title,
}: CombinedProps) => {
  const getCurrentYear = () => new Date().getFullYear()

  const tokkoKey = global_data.pageConfig.keys.tokko
  const { pathname } = useLocation()
  const development = useQueryDevelopment(development_id, tokkoKey)
  const property = useQueryProperty(property_id, tokkoKey)
  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const social = global_data.pageConfig.social
  const logo = global_data.logo
  const college = global_data.college + ' ' + global_data.registration_number
  const captcha = global_data.pageConfig.keys.captcha
  const siteId = global_data.id

  const {
    updaterFavorites, // map,
  }: StatePropertiesSlice = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )

  const [sendContactForm] = useSendContactFormMutation()
  const [sendContactFormMediacore] = useSendContactFormMutationMediacore()

  const [disabled, setDisabled] = useState(true)

  const [closeWhatsapp, setCloseWhatsapp] = useState(true)

  const icons: string = global_data.pageConfig.design.icons
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const realEstateName = global_data.name

  const [token, setValueToken] = useState('')

  function onChange(value: any) {
    setValueToken(value)
  }

  const sendForm = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const tags = ['Sitio Web', 'Newsletter', realEstateName]
    sendContactFormMediacore({
      data: { ...datos, tags, token: token },
      type: 'newsletter',
      site: global_data.id,
    })
    ;(document.getElementById('formNewsletter') as HTMLFormElement)?.reset()
    return toast.success('Te suscribiste a nuestro newsletter correctamente.')
  }

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    })
  }

  const [datos, setDatos] = useState({
    email: '',
  })

  const API_KEY = global_data.pageConfig.keys.tokko

  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )

  const branchesWa = advanced_whatsapp || false

  useEffect(() => {
    const update = () => {
      // dispatch(updateFields(datos))
    }
    update()
    if (!captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [])

  const [isComponentMounted, setIsComponentMounted] = useState(false)

  useEffect(() => {
    setIsComponentMounted(true)
  }, [])

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))

  const countFavorites = listFavorites.length

  useEffect(() => {
    if (isBrowser) {
      setListFavorites(getfavorites('prop', 'template-site'))
    }
  }, [updaterFavorites])

  const scrollTop = () => {
    if (isBrowser) {
      var body = $('html, body')
      body.stop().animate({ scrollTop: 0 }, 500, 'swing', function () {})
    }
  }

  const getLinkSocial = (type: string) => {
    const data_social = social_links || social
    switch (type) {
      case 'facebook': {
        return data_social.facebook
      }
      case 'tiktok': {
        return data_social.tiktok
      }
      case 'twitter': {
        return data_social.twitter
      }
      case 'instagram': {
        return data_social.instagram
      }
      case 'linkedin': {
        return data_social.linkedin
      }
      case 'youtube': {
        return data_social.youtube
      }
    }
  }

  return (
    isComponentMounted && (
      <div
        className={'footer-7 position-relative'}
        style={{ background: backgroundHexa }}
      >
        <button
          onClick={() => {
            scrollToElement('header')
            scrollTop()
          }}
          className={
            'btn goTop-btn align-items-center justify-content-center ' +
            (pathname.includes('alquiler') ||
            pathname.includes('venta') ||
            pathname.includes('temporario')
              ? 'd-none'
              : 'd-flex')
          }
        >
          <i className="icon-arrow"></i>
        </button>
        <Container fluid>
          <Row className="row">
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ order: 1 }}
              className="mb-lg-5 mb-0 order-lg-1 order-1"
            >
              <Row>
                <Col
                  lg={modern_version ? 4 : 3}
                  xs={{ order: 1 }}
                >
                  <Row className="item-contact mb-lg-0 mb-5">
                    <Col
                      lg={12}
                      xs={12}
                    >
                      <ElementHTML
                        className="mb-lg-5 mb-4"
                        style={{ color: titleHexa }}
                        elementType={titleElement || 'h5'}
                        children={titles?.global}
                      />
                    </Col>
                    <Col
                      lg={12}
                      xs={12}
                    >
                      <a
                        target="_blank"
                        style={{ color: paragraphHexa }}
                        className={'hover-footer-link d-block'}
                        href={'mailto:' + contact_data?.email}
                      >
                        {contact_data?.email}
                      </a>
                      {contact_data?.phoneLine?.map(
                        (phone, index) =>
                          phone && (
                            <p
                              style={{ color: paragraphHexa }}
                              key={index}
                            >
                              Teléfono {phone.replace('54911', '54 9 11 ')}
                            </p>
                          ),
                      )}
                      {!hidden &&
                        contact_data?.phoneMobile?.map(
                          (whatsapp, index) =>
                            whatsapp.length > 0 && (
                              <a
                                style={{ color: paragraphHexa }}
                                key={index}
                                className={'hover-footer-link d-block'}
                                target="_blank"
                                href={
                                  'https://api.whatsapp.com/send?' +
                                  'phone=' +
                                  whatsapp.replaceAll('-', '')
                                }
                              >
                                Whatsapp +{whatsapp.replace('54911', '54 9 11 ')}
                              </a>
                            ),
                        )}
                      <a className="d-block">{college}</a>
                    </Col>
                    {button?.link && (
                      <Col
                        xs={12}
                        lg={12}
                      >
                        <Link
                          to={button?.link}
                          className={'btn btn-primary border ' + 'submit'}
                          aria-label="Boton de contacto"
                        >
                          {button?.value}
                          <i className={'icon-arrow-stick ms-4' + iconsStyle}></i>
                        </Link>
                      </Col>
                    )}
                  </Row>
                  {modern_version && (
                    <Row
                      className={
                        'item-contact row mb-5 mb-lg-0 button-separator d-none d-lg-block' +
                        (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                          ? ' d-block'
                          : ' d-none')
                      }
                    >
                      <ElementHTML
                        className="mb-lg-5 mb-4 mt-lg-5 mt-3"
                        style={{ color: titleHexa }}
                        elementType={titleElement || 'h5'}
                        children={titles?.modules}
                      />
                      <Col
                        lg={11}
                        xl={9}
                      >
                        {INVESTOR_CONFIGURATION?.module && (
                          <div className="d-block">
                            <a
                              className="btn btn-investor border btn-primary mb-2 d-block d-lg-flex justify-content-between"
                              target={'_blank'}
                              href={INVESTOR_CONFIGURATION.url_login}
                            >
                              {INVESTOR_CONFIGURATION.text_cta || 'INVERSORES'}
                              <img
                                src={icon2}
                                alt=""
                              />
                            </a>
                          </div>
                        )}
                        {OWNER_CONFIGURATION?.module && (
                          <div className="d-block">
                            <a
                              className="btn btn-investor border btn-primary mb-2 d-block d-lg-flex justify-content-between"
                              target={'_blank'}
                              href={OWNER_CONFIGURATION.url_login}
                            >
                              {OWNER_CONFIGURATION.text_cta || 'PROPIETARIOS'}
                              <img
                                src={icon1}
                                alt=""
                              />
                            </a>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
                {modern_version && (
                  <Col
                    lg={4}
                    xxl={3}
                    xs={{ order: 2 }}
                    className="d-lg-block mb-5 mb-lg-0"
                  >
                    <ElementHTML
                      style={{ color: titleHexa }}
                      className="mb-lg-5 mb-4"
                      children={titles?.branch_office}
                      elementType={titleElement || 'h5'}
                    />
                    {branch_office?.length > 0 &&
                      branch_office.map((branch, index) => (
                        <Row
                          className="item-contact d-lg-block mb-lg-4 mb-4"
                          key={index}
                        >
                          <Col
                            lg={12}
                            xs={12}
                          ></Col>
                          <Col
                            xs={12}
                            lg={12}
                          >
                            <p style={{ color: paragraphHexa }}>{branch?.address} </p>
                            <p style={{ color: paragraphHexa }}>
                              {branch?.city}, {branch?.province}
                            </p>
                            {branch?.contact_data?.emails?.map((email, index) => (
                              <a
                                style={{ color: paragraphHexa }}
                                key={index}
                                target="_blank"
                                className={'hover-footer-link '}
                                href={'mailto:' + email}
                              >
                                {email}
                              </a>
                            ))}
                            {branch?.contact_data?.phonesLine?.map((phone, index) => (
                              <p
                                style={{ color: paragraphHexa }}
                                key={index}
                              >
                                Teléfono {phone.replace('54911', '54 9 11 ')}
                              </p>
                            ))}
                            {branch?.contact_data?.phonesMobile?.map(
                              (whatsapp, index) =>
                                whatsapp.length > 0 && (
                                  <a
                                    style={{ color: paragraphHexa }}
                                    key={index}
                                    className={'hover-footer-link'}
                                    target="_blank"
                                    href={
                                      'https://api.whatsapp.com/send?' +
                                      'phone=' +
                                      whatsapp.replaceAll('-', '')
                                    }
                                  >
                                    Whatsapp + {whatsapp.replace('54911', '54 9 11 ')}
                                  </a>
                                ),
                            )}
                            <p
                              style={{ color: paragraphHexa }}
                              className={branch?.college === '' ? 'd-none' : ''}
                            >
                              Matrícula {branch?.college}
                            </p>
                            <p
                              style={{ color: paragraphHexa }}
                              className={branch?.opening_hours === '' ? 'd-none' : ''}
                            >
                              {branch?.opening_hours}
                            </p>
                            {topLogo && (
                              <div className="  mt-3">
                                <a
                                  className="btn btn-contact"
                                  href={'/contacto'}
                                >
                                  Contactanos
                                </a>
                              </div>
                            )}
                          </Col>
                        </Row>
                      ))}
                  </Col>
                )}
                {!modern_version &&
                  branch_office?.length > 0 &&
                  branch_office.map((branch, index) => (
                    <Col
                      lg={3}
                      xxl={3}
                      xs={{ order: 2 }}
                      className="d-lg-block mb-5 mb-lg-0"
                    >
                      <Row
                        className="tem-contact d-lg-block mb-lg-4 mb-4"
                        key={index}
                      >
                        <ElementHTML
                          style={{ color: titleHexa }}
                          className="mb-lg-5 mb-4"
                          children={titles?.branch_office}
                          elementType={titleElement || 'h5'}
                        />
                        <Col
                          lg={12}
                          xs={12}
                        ></Col>
                        <Col
                          xs={12}
                          lg={12}
                        >
                          <p style={{ color: paragraphHexa }}>{branch?.address} </p>
                          <p style={{ color: paragraphHexa }}>
                            {branch?.city}, {branch?.province}
                          </p>
                          {branch?.contact_data?.emails?.map((email, index) => (
                            <a
                              style={{ color: paragraphHexa }}
                              key={index}
                              target="_blank"
                              className={'hover-footer-link '}
                              href={'mailto:' + email}
                            >
                              {email}
                            </a>
                          ))}
                          {branch?.contact_data?.phonesLine?.map((phone, index) => (
                            <p
                              style={{ color: paragraphHexa }}
                              key={index}
                            >
                              Teléfono {phone.replace('54911', '54 9 11 ')}
                            </p>
                          ))}
                          {branch?.contact_data?.phonesMobile?.map(
                            (whatsapp, index) =>
                              whatsapp.length > 0 && (
                                <a
                                  style={{ color: paragraphHexa }}
                                  key={index}
                                  className={'hover-footer-link'}
                                  target="_blank"
                                  href={
                                    'https://api.whatsapp.com/send?' +
                                    'phone=' +
                                    whatsapp.replaceAll('-', '')
                                  }
                                >
                                  Whatsapp + {whatsapp.replace('54911', '54 9 11 ')}
                                </a>
                              ),
                          )}
                          <p
                            style={{ color: paragraphHexa }}
                            className={branch?.college === '' ? 'd-none' : ''}
                          >
                            Matrícula {branch?.college}
                          </p>
                          <p
                            style={{ color: paragraphHexa }}
                            className={branch?.opening_hours === '' ? 'd-none' : ''}
                          >
                            {branch?.opening_hours}
                          </p>
                          {topLogo && (
                            <div className="  mt-3">
                              <a
                                className="btn btn-contact"
                                href={'/contacto'}
                              >
                                Contactanos
                              </a>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                <Col
                  lg={{ span: 3, order: 5 }}
                  xxl={{ offset: modern_version ? 2 : 0 }}
                  xs={{ order: 5 }}
                >
                  {/* <div className={ "content-contact mt-auto " + (investor.id ? "" : "enabled-border")}> */}
                  <div className={'content-contact mt-auto mb-lg-0 mb-5 ' + 'enabled-border'}>
                    <ElementHTML
                      style={{ color: titleHexa }}
                      className="mb-lg-5 mb-4"
                      children={titles?.newsletter}
                      elementType={titleElement || 'h5'}
                    />
                    <form
                      onSubmit={sendForm}
                      id="formNewsletter"
                    >
                      <div className="d-flex mb-lg-3">
                        <input
                          type="email"
                          onChange={handleInputChange}
                          name="email"
                          required
                          placeholder="Email*"
                          style={{ borderColor: paragraphHexa, color: paragraphHexa }}
                        />
                      </div>
                      <div className="d-flex">
                        {captcha ? (
                          <ReCAPTCHA
                            sitekey={captcha}
                            onChange={(value) => {
                              setDisabled(false)
                              onChange(value)
                            }}
                          />
                        ) : (
                          ''
                        )}
                        <button
                          disabled={disabled}
                          type="submit"
                          className={'btn btn-primary border ' + 'submit'}
                          aria-label="Boton enviar formulario"
                        >
                          {title || 'SUBSCRIBE'}
                          <i className={'icon-arrow-stick ms-4' + iconsStyle}></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <footer
          className="footer-7"
          style={{ background: backgroundHexa }}
        >
          <Container fluid>
            <div
              className="copy-content d-lg-flex w-100 justify-content-between"
              style={{ borderColor: paragraphHexa }}
            >
              <p
                className="copy"
                style={{ color: paragraphHexa }}
              >
                Promoted by{' '}
                <a
                  target="_blank"
                  style={{ color: '#00A1FF' }}
                  href="https://mediahaus.com.ar"
                >
                  {' '}
                  MediaHaus
                </a>{' '}
                - Powered by{' '}
                <a
                  style={{ color: paragraphHexa }}
                  target="_blank"
                  href="https://mediacore.app/"
                >
                  {' '}
                  MediaCore
                </a>
                <br />
                <span
                  className="white"
                  style={{ color: paragraphHexa }}
                >
                  Todos los derechos reservados ® {getCurrentYear()}
                </span>
              </p>
              <div className="redes d-flex align-items-center">
                <p
                  className="me-3"
                  style={{ color: '#fff' }}
                >
                  {titles?.social}
                </p>
                {getLinkSocial('instagram') && (
                  <a
                    target="_blank"
                    aria-label="Lea más en la red social? instagram"
                    href={getLinkSocial('instagram')}
                    className={getLinkSocial('instagram') ? 'd-inline mx-lg-3 mx-2' : 'd-none'}
                  >
                    <i
                      style={{ color: titleHexa }}
                      className={`icon icon-instagram${iconsStyle}`}
                    ></i>
                  </a>
                )}
                {getLinkSocial('facebook') && (
                  <a
                    target="_blank"
                    aria-label="Lea más en la red social? facebook"
                    href={getLinkSocial('facebook')}
                    className={getLinkSocial('facebook') ? 'd-inline mx-lg-3 mx-2' : 'd-none'}
                  >
                    <i
                      style={{ color: titleHexa }}
                      className={`icon icon-facebook${iconsStyle}`}
                    ></i>
                  </a>
                )}
                {getLinkSocial('linkedin') && (
                  <a
                    target="_blank"
                    aria-label="Lea más en la red social? linkedin"
                    href={getLinkSocial('linkedin')}
                    className={getLinkSocial('linkedin') ? 'd-inline mx-lg-3 mx-2' : 'd-none'}
                  >
                    <i
                      style={{ color: titleHexa }}
                      className={`icon icon-linkedin${iconsStyle}`}
                    ></i>
                  </a>
                )}
                {getLinkSocial('youtube') && (
                  <a
                    target="_blank"
                    aria-label="Lea más en la red social? youtube"
                    href={getLinkSocial('youtube')}
                    className={getLinkSocial('youtube') ? 'd-inline mx-lg-3 mx-2' : 'd-none'}
                  >
                    <i
                      style={{ color: titleHexa }}
                      className={`icon icon-youtube${iconsStyle}`}
                    ></i>
                  </a>
                )}
                {getLinkSocial('twitter') && (
                  <a
                    target="_blank"
                    aria-label="Lea más en la red social? twitter"
                    href={getLinkSocial('twitter')}
                    className={getLinkSocial('twitter') ? 'd-inline mx-lg-3 mx-2' : 'd-none'}
                  >
                    <i
                      style={{ color: titleHexa }}
                      className={`icon icon-twitter${iconsStyle}`}
                    ></i>
                  </a>
                )}
                {getLinkSocial('tiktok') && (
                  <a
                    target="_blank"
                    aria-label="Lea mas en la red social tiktok"
                    href={getLinkSocial('tiktok')}
                    className={getLinkSocial('tiktok') ? 'd-inline mx-2' : 'd-none'}
                  >
                    <i
                      style={{ color: titleHexa }}
                      className={`icon-tiktok${iconsStyle}`}
                    ></i>
                  </a>
                )}
              </div>
            </div>
          </Container>
        </footer>
      </div>
    )
  )
}

export default Footer7
