import React, { useEffect } from 'react'
import { store } from '@redux/store'
import { Provider } from 'react-redux'
import Modules from '@components/Modules'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'

export const wrapRootElement = ({ element }: any) => {
  return <Provider store={store}>{element}</Provider>
}

const NavigationHandler = () => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    query GlobalStaticData {
      siteCompilerData {
        prehome_components {
          type_id
        }
        divisions {
          path
        }
      }
    }
  `)

  const isSiteCompiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'
  const useHomeFirstDivision =
    isSiteCompiler && data?.siteCompilerData?.prehome_components?.length === 0
  const firstDivisionPath = isSiteCompiler && data?.siteCompilerData?.divisions[0]?.path

  useEffect(() => {
    if (location.pathname === '/' && useHomeFirstDivision && firstDivisionPath) {
      navigate(firstDivisionPath)
    }
  }, [useHomeFirstDivision, firstDivisionPath, location.pathname])

  return null
}
export const wrapPageElement = ({ element, props }: any) => {
  const {
    pageContext: { global_data, hidden_header, hidden_footer, divisions, three },
    development_id,
    property_id,
    novelty_id,
  } = props

  const {
    content: { header, pages, footer },
  } = global_data

  let Header
  try {
    Header = require(`./components/header/${header.type_id}`).default
  } catch (e) {
    return <></>
  }

  let Footer
  try {
    Footer = require(`./components/footer/${footer.type_id}`).default
  } catch (e) {
    return <></>
  }

  return (
    <>
      <NavigationHandler />
      <Modules
        development_id={development_id}
        property_id={property_id}
        novelty_id={novelty_id}
        pages={pages}
        global_data={global_data}
      />
      {!hidden_header && (
        <Header
          development_id={development_id}
          property_id={property_id}
          novelty_id={novelty_id}
          three={three}
          {...{ type_id: header.type_id, pages, global_data, divisions, ...header.data }}
        />
      )}
      {element}
      {!hidden_footer && (
        <Footer
          {...{
            type_id: footer.type_id,
            data: footer.data,
            ...footer.data,
            pages,
            global_data,
            development_id,
            property_id,
            novelty_id,
          }}
        />
      )}
    </>
  )
}
