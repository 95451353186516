import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { RealEstateDataFooterData, RealEstateDataPage } from 'types'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { scrollToElement } from '@helpers/helper.rendering'
import { useLocation } from '@reach/router'
import useQueryDevelopment from '@hooks/useQueryDevelopment'
import useQueryProperty from '@hooks/useQueryProperty'
import $ from 'jquery'
import Arrow from './arrow.png'

interface ExternalProps {
  pages: RealEstateDataPage[]
  currentPage: RealEstateDataPage[]
  development_id?: string
  property_id?: string
}

const isBrowser = typeof window !== 'undefined'

type CombinedProps = RealEstateDataFooterData & ExternalProps
const Footer3 = ({
  titles,
  global_data,
  development_id,
  property_id,
  footer_files,
}: CombinedProps) => {
  const getCurrentYear = () => new Date().getFullYear()

  const tokkoKey = global_data.pageConfig.keys.tokko
  const { pathname } = useLocation()
  const development = useQueryDevelopment(development_id, tokkoKey)
  const property = useQueryProperty(property_id, tokkoKey)
  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const social = global_data.pageConfig.social
  const logo = global_data.logo
  const college = global_data.college + ' ' + global_data.registration_number
  const captcha = global_data.pageConfig.keys.captcha
  const siteId = global_data.id

  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )

  const [isComponentMounted, setIsComponentMounted] = useState(false)

  useEffect(() => {
    setIsComponentMounted(true)
  }, [])

  const scrollTop = () => {
    if (isBrowser) {
      var body = $('html, body')
      body.stop().animate({ scrollTop: 0 }, 500, 'swing', function () {})
    }
  }

  const icons = global_data?.pageConfig?.design?.icons || 'Solid'

  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

  const Form = require(`@components/form/1`)?.default

  return (
    isComponentMounted && (
      <footer className="c p-y-config text-lg-start text-center">
        <button
          onClick={() => {
            scrollToElement('header')
            scrollTop()
          }}
          className="btn goTop-btn d-flex align-items-center justify-content-center"
        >
          <img
            src={Arrow}
            alt=""
            className="arrow"
          />
          {/* <i className="icon-arrow-stick"></i> */}
        </button>
        <Container fluid>
          <Row>
            <Col
              lg={{ span: 3, order: 1, offset: 1 }}
              xs={{ order: '2' }}
            >
              <div className="d-flex flex-column">
                {branch_office.map((branch, index) => (
                  <div
                    className="mb-5 column column-height d-flex flex-column"
                    key={index}
                  >
                    <h5 className="mb-5 mt-4 mt-lg-0">
                      {titles?.branch_office} {branch.name}
                    </h5>
                    <p className="text-white">
                      {branch?.address}, {branch?.city}
                    </p>
                    <p className="text-white">
                      {branch?.province}
                      {branch?.country ? ', ' + branch?.country : ''}
                    </p>
                    {branch?.contact_data?.phonesMobile?.map((whatsapp, index) => (
                      <p
                        className="mt-3 mt-lg-0 text-white"
                        key={index}
                      >
                        WA: 
                        <a
                          className={'text-white'}
                          target="_blank"
                          href={
                            'https://api.whatsapp.com/send?' +
                            'phone=' +
                            whatsapp.replaceAll('-', '')
                          }
                        >
                          {whatsapp.replace('5411', '(54-11) ')}
                        </a>
                      </p>
                    ))}
                    {branch?.contact_data?.phonesLine?.map((whatsapp, index) => (
                      <p
                        className="mt-3 mt-lg-0 text-white"
                        key={index}
                      >
                        TEL: 
                        {whatsapp.replace('5411', '(54-11) ')}
                      </p>
                    ))}
                    {branch?.opening_hours && (
                      <p className="text-white mt-3 mt-lg-0">{branch?.opening_hours}</p>
                    )}
                    {branch?.college !== '' && (
                      <p className="text-white mt-3 mt-lg-0">{branch?.college}</p>
                    )}
                  </div>
                ))}
              </div>
              {branch_office.length == 1 && (
                <div
                  className={
                    (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                      ? 'd-flex'
                      : 'd-none ') + ' mb-5 column flex-column'
                  }
                  style={{ minHeight: 'auto' }}
                >
                  <h5 className="mb-5 mt-4 mt-lg-0">{titles?.modules}</h5>
                  {INVESTOR_CONFIGURATION?.module && (
                    <div className="d-block pb-2">
                      <a
                        className="btn btn-white border text-white mb-2"
                        target={'_blank'}
                        href={INVESTOR_CONFIGURATION.url_login}
                      >
                        {INVESTOR_CONFIGURATION.text_cta || 'Acceso Inversores'}

                        <i className={'icon-investment-light'}></i>
                      </a>
                    </div>
                  )}
                  {OWNER_CONFIGURATION?.module && (
                    <div className="d-block">
                      <a
                        className="btn btn-white border text-white"
                        target={'_blank'}
                        href={OWNER_CONFIGURATION.url_login}
                      >
                        {OWNER_CONFIGURATION.text_cta || 'Acceso Propietarios'}

                        <i className={'icon-investor-access-light '}></i>
                      </a>
                    </div>
                  )}
                </div>
              )}
              <p className="copy d-none d-lg-block">
                Promoted by{' '}
                <a
                  style={{ color: '#17A1FF' }}
                  target="_blank"
                  href="https://mediahaus.com.ar/"
                >
                  MediaHaus
                </a>{' '}
                - Powered by{' '}
                <a
                  style={{ color: '#17A1FF' }}
                  target="_blank"
                  href="https://mediacore.app/"
                >
                  MediaCore
                </a>
                <br />
                Todos los derechos reservados ® {getCurrentYear()}
              </p>
            </Col>
            <Col
              lg={{ span: 3, order: 2 }}
              xs={{ order: '3' }}
            >
              <div className="d-flex flex-column">
                <div className="mb-5 column-height column d-flex flex-column">
                  <h5 className="mb-5 mt-4 mt-lg-0">{titles?.social}</h5>
                  {social.facebook && (
                    <p>
                      <a
                        href={social.facebook}
                        target="_blank"
                        className="text-white"
                      >
                        Facebook
                      </a>
                    </p>
                  )}
                  {social.instagram && (
                    <p>
                      <a
                        href={social.instagram}
                        target="_blank"
                        className="text-white"
                      >
                        Instagram
                      </a>
                    </p>
                  )}
                  {social.linkedin && (
                    <p>
                      <a
                        href={social.linkedin}
                        target="_blank"
                        className="text-white"
                      >
                        Linkedin
                      </a>
                    </p>
                  )}
                  {social.twitter && (
                    <p>
                      <a
                        href={social.twitter}
                        target="_blank"
                        className="text-white"
                      >
                        Twitter
                      </a>
                    </p>
                  )}
                  {social.youtube && (
                    <p>
                      <a
                        href={social.youtube}
                        target="_blank"
                        className="text-white"
                      >
                        Youtube
                      </a>
                    </p>
                  )}
                </div>
                {footer_files && footer_files?.length > 0 && (
                  <div className="d-lg-none d-flex justify-content-center flex-column">
                    <h5 className="mb-5">{titles?.downloadables}</h5>
                    {footer_files?.map((file) => (
                      <div className="d-block mx-auto">
                        <a
                          className="btn btn-white border text-white"
                          target={'_blank'}
                          href={file.file}
                        >
                          {file.title ?? ''}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                {branch_office.length > 1 && (
                  <div
                    className={
                      (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                        ? 'd-flex'
                        : 'd-none ') + 'mb-5 column-height column flex-column'
                    }
                  >
                    <h5 className="mb-5 mt-4 mt-lg-0">{titles?.modules}</h5>
                    {INVESTOR_CONFIGURATION?.module && (
                      <div className="d-block pb-2">
                        <a
                          className="btn btn-white border text-white mb-2"
                          target={'_blank'}
                          href={INVESTOR_CONFIGURATION.url_login}
                        >
                          {INVESTOR_CONFIGURATION.text_cta || 'Acceso Inversores'}

                          <i className={'icon-investment-light'}></i>
                        </a>
                      </div>
                    )}
                    {OWNER_CONFIGURATION?.module && (
                      <div className="d-block">
                        <a
                          className="btn btn-white border text-white"
                          target={'_blank'}
                          href={OWNER_CONFIGURATION.url_login}
                        >
                          {OWNER_CONFIGURATION.text_cta || 'Acceso Propietarios'}

                          <i className={'icon-investor-access-light '}></i>
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <p className="copy d-block d-lg-none">
                  Promoted by{' '}
                  <a
                    style={{ color: '#17A1FF' }}
                    target="_blank"
                    href="https://mediahaus.com.ar/"
                  >
                    MediaHaus
                  </a>{' '}
                  - Powered by{' '}
                  <a
                    style={{ color: '#17A1FF' }}
                    target="_blank"
                    href="https://mediacore.app/"
                  >
                    MediaCore
                  </a>
                  <br />
                  Todos los derechos reservados ® {getCurrentYear()}
                </p>
              </div>

              {footer_files && footer_files?.length > 0 && (
                <div className="d-lg-flex d-none flex-column">
                  <h5 className="mb-5">{titles?.downloadables}</h5>
                  {footer_files?.map((file) => (
                    <div className="d-block">
                      <a
                        className="btn btn-white border text-white"
                        target={'_blank'}
                        href={file.file}
                      >
                        {file.title ?? ''}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </Col>
            <Col
              lg={{ span: 5, order: 3 }}
              xs={{ order: '1' }}
            >
              <Row justify={'center'}>
                <Col xl={10}>
                  <h5 className="mb-5 mt-4 mt-lg-0">{titles?.global}</h5>
                  <Form
                    global_data={global_data}
                    is_short={true}
                    button={{ value: 'Enviar' }}
                    focusSecondary={true}
                    smallPadding={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  )
}

export default Footer3
