import React, { useEffect, useState } from 'react'
import axios from 'axios'

interface Props {
  src: string
  className?: string
  onClick?: () => void
}

const InlineSVG = ({ src, className, onClick }: Props) => {
  const [svgContent, setSvgContent] = useState<string | null>(null)

  useEffect(() => {
    const loadSVG = async () => {
      try {
        const response = await axios.get(src)
        setSvgContent(response.data)
      } catch (error) {
        setSvgContent(null)
      }
    }
    loadSVG()
  }, [src])

  if (!svgContent) {
    return null
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: svgContent }}
      style={{ display: 'inline-block' }}
      onClick={onClick}
    />
  )
}

export default InlineSVG
